exports.cities = [
  {
    label: "Morocco",
    options: [
      { label: "Casablanca", value: "1" },
      { label: "Fes", value: "2" },
      { label: "Salé", value: "3" },
      { label: "Marrakech", value: "4" },
      { label: "Tanger", value: "5" },
      { label: "Rabat", value: "6" },
      { label: "Meknes", value: "7" },
      { label: "Oujda", value: "8" },
      { label: "Kenitra", value: "9" },
      { label: "Agadir", value: "10" },
      { label: "Tetouan", value: "11" },
      { label: "Safi", value: "12" },
      { label: "Temara", value: "13" },
      { label: "Inzegan", value: "14" },
      { label: "Mohammedia", value: "15" },
      { label: "Laayoune", value: "16" },
      { label: "Khouribga", value: "17" },
      { label: "Beni Mellal", value: "18" },
      { label: "El jadida", value: "19" },
      { label: "Taza", value: "20" },
      { label: "Ait Melloul", value: "21" },
      { label: "Nador", value: "22" },
      { label: "Settat", value: "23" },
      { label: "Ksar El Kbir", value: "24" },
      { label: "Larache", value: "25" },
      { label: "Khemisset", value: "26" },
      { label: "Guelmim", value: "27" },
      { label: "Berrechid", value: "28" },
      { label: "Wad Zam", value: "29" },
      { label: "Fkih BenSaleh", value: "30" },
      { label: "Taourirt", value: "31" },
      { label: "Berkane", value: "32" },
      { label: "Sidi Sliman", value: "33" },
      { label: "Errachidia", value: "34" },
      { label: "Sidi Kacem", value: "35" },
      { label: "Khenifra", value: "36" },
      { label: "Tifelt", value: "37" },
      { label: "Essaouira", value: "38" },
      { label: "Taroudant", value: "39" },
      { label: "Kelaat Sraghna", value: "40" },
      { label: "Oulad Teima", value: "41" },
      { label: "Youssoufia", value: "42" },
      { label: "Sefrou", value: "43" },
      { label: "Ben Guerir", value: "44" },
      { label: "Tan-Tan", value: "45" },
      { label: "Ouazzane", value: "46" },
      { label: "Guercif", value: "47" },
      { label: "Dakhla", value: "48" },
      { label: "Al hoceima", value: "49" },
      { label: "Fnideq", value: "50" },
      { label: "Ouarzazate", value: "51" },
      { label: "Tiznit", value: "52" },
      { label: "Suq Sebt Oulad Nama", value: "53" },
      { label: "Azrou", value: "54" },
      { label: "Lahraouyine", value: "55" },
      { label: "Bensliman", value: "56" },
      { label: "Midelt", value: "57" },
      { label: "Jrada", value: "58" },
      { label: "Skhirat", value: "59" },
      { label: "Souk Larbaa", value: "60" },
      { label: "Aïn Harrouda", value: "61" },
      { label: "Bejaad", value: "62" },
      { label: "Kasbat Tadla", value: "63" },
      { label: "Sidi Bennour", value: "64" },
      { label: "Martil", value: "65" },
      { label: "Lqliaa", value: "66" },
      { label: "Boujdor", value: "67" },
      { label: "Azemour", value: "68" },
      { label: "Mdyaq", value: "69" },
      { label: "Tinghir", value: "70" },
      { label: "El Arwi", value: "71" },
      { label: "Chefchawn", value: "72" },
      { label: "Mrirt", value: "73" },
      { label: "Zagora", value: "74" },
      { label: "El Aioun Sidi Mellouk", value: "75" },
      { label: "Lamkansa", value: "76" },
      { label: "Smara", value: "77" },
      { label: "Taounate", value: "78" },
      { label: "Bin Anşār", value: "79" },
      { label: "Sidi Yahya El Gharb", value: "80" },
      { label: "Zaio", value: "81" },
      { label: "Amalou Ighriben", value: "82" },
      { label: "Assilah", value: "83" },
      { label: "Azilal", value: "84" },
      { label: "Mechra Bel Ksiri", value: "85" },
      { label: "El Hajeb", value: "86" },
      { label: "Bouznika", value: "87" },
      { label: "Imzouren", value: "88" },
      { label: "Tahla", value: "89" },
      { label: "BouiZazarene Ihaddadene", value: "90" },
      { label: "Ain El Aouda", value: "91" },
      { label: "Bouarfa", value: "92" },
      { label: "Arfoud", value: "93" },
      { label: "Demnate", value: "94" },
      { label: "Sidi sliman echraa", value: "95" },
      { label: "Zawiyat cheikh", value: "96" },
      { label: "Ain Taoujdat", value: "97" },
      { label: "Echemaia", value: "98" },
      { label: "Aourir", value: "99" },
      { label: "Sabaa Aiyoun", value: "100" },
      { label: "Oulad Ayad", value: "101" },
      { label: "Ben Ahmed", value: "102" },
      { label: "Tabounte", value: "103" },
      { label: "Jorf El Melha", value: "104" },
      { label: "Missour", value: "105" },
      { label: "Laataouia", value: "106" },
      { label: "Errich", value: "107" },
      { label: "Zeghanghan", value: "108" },
      { label: "Rissani", value: "109" },
      { label: "Sidi Taibi", value: "110" },
      { label: "Sidi Ifni", value: "111" },
      { label: "Ait Ourir", value: "112" },
      { label: "Ahfir", value: "113" },
      { label: "El Ksiba", value: "114" },
      { label: "El Gara", value: "115" },
      { label: "Drargua", value: "116" },
      { label: "Imin tanout", value: "117" },
      { label: "Goulmima", value: "118" },
      { label: "Karia Ba Mohamed", value: "119" },
      { label: "Mehdya", value: "120" },
      { label: "El Borouj", value: "121" },
      { label: "Bouhdila", value: "122" },
      { label: "Chichaoua", value: "123" },
      { label: "Beni Bouayach", value: "124" },
      { label: "Oulad Berhil", value: "125" },
      { label: "Jmaat Shaim", value: "126" },
      { label: "Bir Jdid", value: "127" },
      { label: "Tata", value: "128" },
      { label: "Boujniba", value: "129" },
      { label: "Temsia", value: "130" },
      { label: "Mediouna", value: "131" },
      { label: "Kelâat MGouna", value: "132" },
      { label: "Sebt Gzoula", value: "133" },
      { label: "Outat El Haj", value: "134" },
      { label: "Imouzzer Kandar", value: "135" },
      { label: "Ain Bni Mathar", value: "136" },
      { label: "Bouskoura", value: "137" },
      { label: "Agourai", value: "138" },
      { label: "Midar", value: "139" },
      { label: "Lalla Mimouna", value: "140" },
      { label: "Ribat El Kheir", value: "141" },
      { label: "Moulay Driss zarhoun", value: "142" },
      { label: "Figuig", value: "143" },
      { label: "Boumia", value: "144" },
      { label: "Tamallalt", value: "145" },
      { label: "Nouaceur", value: "146" },
      { label: "Rommani", value: "147" },
      { label: "Jorf", value: "148" },
      { label: "Ifrane", value: "149" },
      { label: "Bouizakarn", value: "150" },
      { label: "Oulad Mbarek", value: "151" },
      { label: "Afourar", value: "152" },
      { label: "Zmamra", value: "153" },
      { label: "Ait Ishaq", value: "154" },
      { label: "Tit Mellil", value: "155" },
      { label: "Assa", value: "156" },
      { label: "Bhalil", value: "157" },
      { label: "Targuist", value: "158" },
      { label: "Beni Yakhlef", value: "159" },
      { label: "El Menzel", value: "160" },
      { label: "Aguelmouss", value: "161" },
      { label: "Sidi EL Mokhtar", value: "162" },
      { label: "Boumalne Dades", value: "163" },
      { label: "Farkhana", value: "164" },
      { label: "Oulad Abbou", value: "165" },
      { label: "Amizmiz", value: "166" },
      { label: "Boulanouare", value: "167" },
      { label: "Ben Taieb", value: "168" },
      { label: "Ouled Frej", value: "169" },
      { label: "Driouch", value: "170" },
      { label: "Deroua", value: "171" },
      { label: "Hattane", value: "172" },
      { label: "El Marsa", value: "173" },
      { label: "Tamanar", value: "174" },
      { label: "Ait Iaaza", value: "175" },
      { label: "Sidi Allal El Bahraoui", value: "176" },
      { label: "Dar Ould Zidouh", value: "177" },
      { label: "Sid Zouine", value: "178" },
      { label: "Boudnib", value: "179" },
      { label: "Foum Zguid", value: "180" },
      { label: "Tissa", value: "181" },
      { label: "Jaadar", value: "182" },
      { label: "Oulmes", value: "183" },
      { label: "Bouknadel", value: "184" },
      { label: "Harhoura", value: "185" },
      { label: "El Guerdan", value: "186" },
      { label: "Selouane", value: "187" },
      { label: "Maaziz", value: "188" },
      { label: "Oulad M'Rah", value: "189" },
      { label: "Loudaya", value: "190" },
      { label: "Massa", value: "191" },
      { label: "Aklim", value: "192" },
      { label: "Ouaouizaght", value: "193" },
      { label: "Bni Drar", value: "194" },
      { label: "El Kbab", value: "195" },
      { label: "Oued Amlil", value: "196" },
      { label: "Sidi Rahel Chatai", value: "197" },
      { label: "Guigou", value: "198" },
      { label: "Agdz", value: "199" },
      { label: "Khnichet", value: "200" },
      { label: "Karia", value: "201" },
      { label: "Sidi Ahmed", value: "202" },
      { label: "Zag", value: "203" },
      { label: "Oulad Yaich", value: "204" },
      { label: "Tinjdad", value: "205" },
      { label: "Ouad Laou", value: "206" },
      { label: "Tighassaline", value: "207" },
      { label: "Tounfite", value: "208" },
      { label: "Bni Tadjite", value: "209" },
      { label: "Bouanane", value: "210" },
      { label: "Oulad Hriz Sahel", value: "211" },
      { label: "Talsint", value: "212" },
      { label: "Taghjijt", value: "213" },
      { label: "Boulman", value: "214" },
      { label: "Zirara", value: "215" },
      { label: "Taouima", value: "216" },
      { label: "Tahannaout", value: "217" },
      { label: "Bradia", value: "218" },
      { label: "Moulay Abdallah", value: "219" },
      { label: "Sidi Rahal", value: "220" },
      { label: "Tameslohte", value: "221" },
      { label: "Aghbala", value: "222" },
      { label: "El Ouatia", value: "223" },
      { label: "Tendrara", value: "224" },
      { label: "Taznakht", value: "225" },
      { label: "Fam El Hisn", value: "226" },
      { label: "Akka", value: "227" },
      { label: "Dar Gueddari", value: "228" },
      { label: "Itzer", value: "229" },
      { label: "Taliouine", value: "230" },
      { label: "Oualidia", value: "231" },
      { label: "Aoulouz", value: "232" },
      { label: "Moulay Bousselham", value: "233" },
      { label: "Tarfaya", value: "234" },
      { label: "Ghafsai", value: "235" },
      { label: "Foum Jemaa", value: "236" },
      { label: "Ain Leuh", value: "237" },
      { label: "Moulay Bouazza", value: "238" },
      { label: "Kariat Arkmane", value: "239" },
      { label: "Jadida", value: "240" },
      { label: "Sidi Bou Othmane", value: "241" },
      { label: "Oulad Tayeb", value: "242" },
      { label: "Had Kourt", value: "243" },
      { label: "Bab Berrad", value: "244" },
      { label: "Loulad", value: "245" },
      { label: "Zaida", value: "246" },
      { label: "Tafrawt", value: "247" },
      { label: "Khemis Sahel", value: "248" },
      { label: "Ait Baha", value: "249" },
      { label: "Biougra", value: "250" },
      { label: "Dar Bni Karrich", value: "251" },
      { label: "El Hanchane", value: "252" },
      { label: "Sidi Jaber", value: "253" },
      { label: "Irherm", value: "254" },
      { label: "Debdou", value: "255" },
      { label: "Ras El Ma", value: "256" },
      { label: "Laaounate", value: "257" },
      { label: "Hadj Kaddour", value: "258" },
      { label: "Skhour Rhamna", value: "259" },
      { label: "Bzou", value: "260" },
      { label: "Ain Cheggag", value: "261" },
      { label: "Bouderbala", value: "262" },
      { label: "Sidi Smaïl", value: "263" },
      { label: "Oulad Zbair", value: "264" },
      { label: "Bni Chiker", value: "265" },
      { label: "Lakhsas", value: "266" },
      { label: "Talmest", value: "267" },
      { label: "Aknoul", value: "268" },
      { label: "Tiztoutine", value: "269" },
      { label: "Bab Taza", value: "270" },
      { label: "Imouzzer Marmoucha", value: "271" },
      { label: "Gourrama", value: "272" },
      { label: "Ajdir", value: "273" },
      { label: "Mhaya", value: "274" },
      { label: "Oulad Ghadbane", value: "275" },
      { label: "Zrarda", value: "276" },
      { label: "Zoumi", value: "277" },
      { label: "Ain Karma", value: "278" },
      { label: "Thar Essouk", value: "279" },
      { label: "Lagouira", value: "280" },
      { label: "Ras El Ain", value: "281" },
      { label: "Sidi Ali Ben Hamdouche", value: "282" },
      { label: "Sebt Jahjouh", value: "283" },
      { label: "Tiddas", value: "284" },
      { label: "Zaouiat Bougrin", value: "285" },
      { label: "Tafersit", value: "286" },
      { label: "Touissit", value: "287" },
      { label: "Saidia", value: "288" },
      { label: "Lalla Takarkoust", value: "289" },
      { label: "Skhinate", value: "290" },
      { label: "Moulay Brahim", value: "291" },
      { label: "Had soualem", value: "292" },
      { label: "Gueznaia", value: "293" },
      { label: "Moulay Yacoub", value: "294" },
      { label: "Sidi Allal Tazi", value: "295" },
      { label: "Laakarta", value: "296" },
      { label: "Alnif", value: "297" },
      { label: "Dar El Kebdani", value: "298" },
      { label: "Jebha", value: "299" },
      { label: "Ain Erreggada", value: "300" },
      { label: "Sidi Addi", value: "301" },
      { label: "Skoura", value: "302" },
      { label: "Smimou", value: "303" },
      { label: "Ain Jemaa", value: "304" },
      { label: "Timahdite", value: "305" },
      { label: "Aït Dawd", value: "306" },
      { label: "Souk EL Had", value: "307" },
      { label: "Had Bouhssoussen", value: "308" },
      { label: "Oulad Said", value: "309" },
      { label: "Arbaoua", value: "310" },
      { label: "Ain Dorij", value: "311" },
      { label: "Madagh", value: "312" },
      { label: "Tighza", value: "313" },
      { label: "Matmata", value: "314" },
      { label: "Mazagan", value: "315" },
      { label: "Kassita", value: "316" },
      { label: "Bni Hadifa", value: "317" },
      { label: "Oued EL Heimar", value: "318" },
      { label: "Kerrouchen", value: "319" },
      { label: "Tainaste", value: "320" },
      { label: "Guisser", value: "321" },
      { label: "Sidi Boubker", value: "322" },
      { label: "Tamassint", value: "323" },
      { label: "Daouar Sahrij", value: "324" },
      { label: "Aghbalou Nssardane", value: "325" },
      { label: "Tizi Ouasli", value: "326" },
      { label: "Moqrisset", value: "327" },
      { label: "Sebt Lamaarif", value: "328" },
      { label: "Issaguen", value: "329" },
      { label: "Bouguedra", value: "330" },
      { label: "Brikcha", value: "331" },
      { label: "Ighoud", value: "332" },
      { label: "Ajdir, Taza", value: "333" },
      { label: "Oulad Amrane", value: "334" },
      { label: "Kettara", value: "335" },
      { label: "Aoufous", value: "336" },
      { label: "Tafetacht", value: "337" },
      { label: "Naïma", value: "338" },
      { label: "Tnin Sidi Lyamani", value: "339" },
      { label: "Karia", value: "340" },
      { label: "El Mansouria", value: "341" },
      { label: "Essaouira - Festival Gnaoua", value: "342" },
      { label: "Sidi Abdallah Ghiat", value: "343" },
      { label: "Sidi Bouzid", value: "344" },
      { label: "Ounagha", value: "345" },
      { label: "Imilchil", value: "346" },
      { label: "Technopark", value: "347" },
      { label: "Technopolis", value: "348" },
      { label: "Tamesna", value: "349" },
      { label: "Salé al jadida", value: "350" },
      { label: "Aeroport M5", value: "351" },
      { label: "Ain sbaa", value: "352" },
      { label: "Oued Zem", value: "353" },
      { label: "Ain Atiq", value: "354" },
      { label: "Dar Bouazza ", value: "355" },
      { label: "Aeroport Rabat-Salé", value: "356" },
      { label: "Aeroport Fes", value: "357" },
      { label: "Aeroport tanger", value: "358" },
      { label: "Tanger MED", value: "359" },
      { label: "Imsouane", value: "360" },
      { label: "Tafedna", value: "361" },
      { label: "Other", value: "362" },
      { label: "taghazout", value: "363" },
      { label: "Zaouiat Cheikh", value: "364" },
      { label: "Ben Ahmed", value: "365" },
      { label: "Merzouga", value: "366" },
      { label: "Dar Bel Amri", value: "367" },
      { label: "Tantan", value: "368" },
      { label: "Guers Tiallaline", value: "369" },
      {label: "Al Haouz", value: "370"},
    ],
  },
];
exports.countries = [
  {
    label: "",
    options: [
      { value: "1", name: "MOROCCO", label: "Morocco" },
      { value: "144", name: "AFGHANISTAN", label: "Afghanistan" },
      { value: "2", name: "ALBANIA", label: "Albania" },
      { value: "3", name: "ALGERIA", label: "Algeria" },
      { value: "4", name: "AMERICAN SAMOA", label: "American Samoa" },
      { value: "5", name: "ANDORRA", label: "Andorra" },
      { value: "6", name: "ANGOLA", label: "Angola" },
      { value: "7", name: "ANGUILLA", label: "Anguilla" },
      { value: "8", name: "ANTARCTICA", label: "Antarctica" },
      { value: "9", name: "ANTIGUA AND BARBUDA", label: "Antigua and Barbuda" },
      { value: "10", name: "ARGENTINA", label: "Argentina" },
      { value: "11", name: "ARMENIA", label: "Armenia" },
      { value: "12", name: "ARUBA", label: "Aruba" },
      { value: "13", name: "AUSTRALIA", label: "Australia" },
      { value: "14", name: "AUSTRIA", label: "Austria" },
      { value: "15", name: "AZERBAIJAN", label: "Azerbaijan" },
      { value: "16", name: "BAHAMAS", label: "Bahamas" },
      { value: "17", name: "BAHRAIN", label: "Bahrain" },
      { value: "18", name: "BANGLADESH", label: "Bangladesh" },
      { value: "19", name: "BARBADOS", label: "Barbados" },
      { value: "20", name: "BELARUS", label: "Belarus" },
      { value: "21", name: "BELGIUM", label: "Belgium" },
      { value: "22", name: "BELIZE", label: "Belize" },
      { value: "23", name: "BENIN", label: "Benin" },
      { value: "24", name: "BERMUDA", label: "Bermuda" },
      { value: "25", name: "BHUTAN", label: "Bhutan" },
      { value: "26", name: "BOLIVIA", label: "Bolivia" },
      {
        value: "27",
        name: "BOSNIA AND HERZEGOVINA",
        label: "Bosnia and Herzegovina",
      },
      { value: "28", name: "BOTSWANA", label: "Botswana" },
      { value: "29", name: "BOUVET ISLAND", label: "Bouvet Island" },
      { value: "30", name: "BRAZIL", label: "Brazil" },
      {
        value: "31",
        name: "BRITISH INDIAN OCEAN TERRITORY",
        label: "British Indian Ocean Territory",
      },
      { value: "32", name: "BRUNEI DARUSSALAM", label: "Brunei Darussalam" },
      { value: "33", name: "BULGARIA", label: "Bulgaria" },
      { value: "34", name: "BURKINA FASO", label: "Burkina Faso" },
      { value: "35", name: "BURUNDI", label: "Burundi" },
      { value: "36", name: "CAMBODIA", label: "Cambodia" },
      { value: "37", name: "CAMEROON", label: "Cameroon" },
      { value: "38", name: "CANADA", label: "Canada" },
      { value: "39", name: "CAPE VERDE", label: "Cape Verde" },
      { value: "40", name: "CAYMAN ISLANDS", label: "Cayman Islands" },
      {
        value: "41",
        name: "CENTRAL AFRICAN REPUBLIC",
        label: "Central African Republic",
      },
      { value: "42", name: "CHAD", label: "Chad" },
      { value: "43", name: "CHILE", label: "Chile" },
      { value: "44", name: "CHINA", label: "China" },
      { value: "45", name: "CHRISTMAS ISLAND", label: "Christmas Island" },
      {
        value: "46",
        name: "COCOS (KEELING) ISLANDS",
        label: "Cocos (Keeling) Islands",
      },
      { value: "47", name: "COLOMBIA", label: "Colombia" },
      { value: "48", name: "COMOROS", label: "Comoros" },
      { value: "49", name: "CONGO", label: "Congo" },
      {
        value: "50",
        name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
        label: "Congo, the Democratic Republic of the",
      },
      { value: "51", name: "COOK ISLANDS", label: "Cook Islands" },
      { value: "52", name: "COSTA RICA", label: "Costa Rica" },
      { value: "53", name: "COTE D'IVOIRE", label: "Cote D'Ivoire" },
      { value: "54", name: "CROATIA", label: "Croatia" },
      { value: "55", name: "CUBA", label: "Cuba" },
      { value: "56", name: "CYPRUS", label: "Cyprus" },
      { value: "57", name: "CZECH REPUBLIC", label: "Czech Republic" },
      { value: "58", name: "DENMARK", label: "Denmark" },
      { value: "59", name: "DJIBOUTI", label: "Djibouti" },
      { value: "60", name: "DOMINICA", label: "Dominica" },
      { value: "61", name: "DOMINICAN REPUBLIC", label: "Dominican Republic" },
      { value: "62", name: "ECUADOR", label: "Ecuador" },
      { value: "63", name: "EGYPT", label: "Egypt" },
      { value: "64", name: "EL SALVADOR", label: "El Salvador" },
      { value: "65", name: "EQUATORIAL GUINEA", label: "Equatorial Guinea" },
      { value: "66", name: "ERITREA", label: "Eritrea" },
      { value: "67", name: "ESTONIA", label: "Estonia" },
      { value: "68", name: "ETHIOPIA", label: "Ethiopia" },
      {
        value: "69",
        name: "FALKLAND ISLANDS (MALVINAS)",
        label: "Falkland Islands (Malvinas)",
      },
      { value: "70", name: "FAROE ISLANDS", label: "Faroe Islands" },
      { value: "71", name: "FIJI", label: "Fiji" },
      { value: "72", name: "FINLAND", label: "Finland" },
      { value: "73", name: "FRANCE", label: "France" },
      { value: "74", name: "FRENCH GUIANA", label: "French Guiana" },
      { value: "75", name: "FRENCH POLYNESIA", label: "French Polynesia" },
      {
        value: "76",
        name: "FRENCH SOUTHERN TERRITORIES",
        label: "French Southern Territories",
      },
      { value: "77", name: "GABON", label: "Gabon" },
      { value: "78", name: "GAMBIA", label: "Gambia" },
      { value: "79", name: "GEORGIA", label: "Georgia" },
      { value: "80", name: "GERMANY", label: "Germany" },
      { value: "81", name: "GHANA", label: "Ghana" },
      { value: "82", name: "GIBRALTAR", label: "Gibraltar" },
      { value: "83", name: "GREECE", label: "Greece" },
      { value: "84", name: "GREENLAND", label: "Greenland" },
      { value: "85", name: "GRENADA", label: "Grenada" },
      { value: "86", name: "GUADELOUPE", label: "Guadeloupe" },
      { value: "87", name: "GUAM", label: "Guam" },
      { value: "88", name: "GUATEMALA", label: "Guatemala" },
      { value: "89", name: "GUINEA", label: "Guinea" },
      { value: "90", name: "GUINEA-BISSAU", label: "Guinea-Bissau" },
      { value: "91", name: "GUYANA", label: "Guyana" },
      { value: "92", name: "HAITI", label: "Haiti" },
      {
        value: "93",
        name: "HEARD ISLAND AND MCDONALD ISLANDS",
        label: "Heard Island and Mcdonald Islands",
      },
      {
        value: "94",
        name: "HOLY SEE (VATICAN CITY STATE)",
        label: "Holy See (Vatican City State)",
      },
      { value: "95", name: "HONDURAS", label: "Honduras" },
      { value: "96", name: "HONG KONG", label: "Hong Kong" },
      { value: "97", name: "HUNGARY", label: "Hungary" },
      { value: "98", name: "ICELAND", label: "Iceland" },
      { value: "99", name: "INDIA", label: "India" },
      { value: "100", name: "INDONESIA", label: "Indonesia" },
      {
        value: "101",
        name: "IRAN, ISLAMIC REPUBLIC OF",
        label: "Iran, Islamic Republic of",
      },
      { value: "102", name: "IRAQ", label: "Iraq" },
      { value: "103", name: "IRELAND", label: "Ireland" },
      { value: "104", name: "ISRAEL", label: "Israel" },
      { value: "105", name: "ITALY", label: "Italy" },
      { value: "106", name: "JAMAICA", label: "Jamaica" },
      { value: "107", name: "JAPAN", label: "Japan" },
      { value: "108", name: "JORDAN", label: "Jordan" },
      { value: "109", name: "KAZAKHSTAN", label: "Kazakhstan" },
      { value: "110", name: "KENYA", label: "Kenya" },
      { value: "111", name: "KIRIBATI", label: "Kiribati" },
      {
        value: "112",
        name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
        label: "Korea, Democratic People's Republic of",
      },
      { value: "113", name: "KOREA, REPUBLIC OF", label: "Korea, Republic of" },
      { value: "114", name: "KUWAIT", label: "Kuwait" },
      { value: "115", name: "KYRGYZSTAN", label: "Kyrgyzstan" },
      {
        value: "116",
        name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
        label: "Lao People's Democratic Republic",
      },
      { value: "117", name: "LATVIA", label: "Latvia" },
      { value: "118", name: "LEBANON", label: "Lebanon" },
      { value: "119", name: "LESOTHO", label: "Lesotho" },
      { value: "120", name: "LIBERIA", label: "Liberia" },
      {
        value: "121",
        name: "LIBYAN ARAB JAMAHIRIYA",
        label: "Libyan Arab Jamahiriya",
      },
      { value: "122", name: "LIECHTENSTEIN", label: "Liechtenstein" },
      { value: "123", name: "LITHUANIA", label: "Lithuania" },
      { value: "124", name: "LUXEMBOURG", label: "Luxembourg" },
      { value: "125", name: "MACAO", label: "Macao" },
      {
        value: "126",
        name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
        label: "Macedonia, the Former Yugoslav Republic of",
      },
      { value: "127", name: "MADAGASCAR", label: "Madagascar" },
      { value: "128", name: "MALAWI", label: "Malawi" },
      { value: "129", name: "MALAYSIA", label: "Malaysia" },
      { value: "130", name: "MALDIVES", label: "Maldives" },
      { value: "131", name: "MALI", label: "Mali" },
      { value: "132", name: "MALTA", label: "Malta" },
      { value: "133", name: "MARSHALL ISLANDS", label: "Marshall Islands" },
      { value: "134", name: "MARTINIQUE", label: "Martinique" },
      { value: "135", name: "MAURITANIA", label: "Mauritania" },
      { value: "136", name: "MAURITIUS", label: "Mauritius" },
      { value: "137", name: "MAYOTTE", label: "Mayotte" },
      { value: "138", name: "MEXICO", label: "Mexico" },
      {
        value: "139",
        name: "MICRONESIA, FEDERATED STATES OF",
        label: "Micronesia, Federated States of",
      },
      {
        value: "140",
        name: "MOLDOVA, REPUBLIC OF",
        label: "Moldova, Republic of",
      },
      { value: "141", name: "MONACO", label: "Monaco" },
      { value: "142", name: "MONGOLIA", label: "Mongolia" },
      { value: "143", name: "MONTSERRAT", label: "Montserrat" },

      { value: "145", name: "MOZAMBIQUE", label: "Mozambique" },
      { value: "146", name: "MYANMAR", label: "Myanmar" },
      { value: "147", name: "NAMIBIA", label: "Namibia" },
      { value: "148", name: "NAURU", label: "Nauru" },
      { value: "149", name: "NEPAL", label: "Nepal" },
      { value: "150", name: "NETHERLANDS", label: "Netherlands" },
      {
        value: "151",
        name: "NETHERLANDS ANTILLES",
        label: "Netherlands Antilles",
      },
      { value: "152", name: "NEW CALEDONIA", label: "New Caledonia" },
      { value: "153", name: "NEW ZEALAND", label: "New Zealand" },
      { value: "154", name: "NICARAGUA", label: "Nicaragua" },
      { value: "155", name: "NIGER", label: "Niger" },
      { value: "156", name: "NIGERIA", label: "Nigeria" },
      { value: "157", name: "NIUE", label: "Niue" },
      { value: "158", name: "NORFOLK ISLAND", label: "Norfolk Island" },
      {
        value: "159",
        name: "NORTHERN MARIANA ISLANDS",
        label: "Northern Mariana Islands",
      },
      { value: "160", name: "NORWAY", label: "Norway" },
      { value: "161", name: "OMAN", label: "Oman" },
      { value: "162", name: "PAKISTAN", label: "Pakistan" },
      { value: "163", name: "PALAU", label: "Palau" },
      {
        value: "164",
        name: "PALESTINIAN TERRITORY, OCCUPIED",
        label: "Palestinian Territory, Occupied",
      },
      { value: "165", name: "PANAMA", label: "Panama" },
      { value: "166", name: "PAPUA NEW GUINEA", label: "Papua New Guinea" },
      { value: "167", name: "PARAGUAY", label: "Paraguay" },
      { value: "168", name: "PERU", label: "Peru" },
      { value: "169", name: "PHILIPPINES", label: "Philippines" },
      { value: "170", name: "PITCAIRN", label: "Pitcairn" },
      { value: "171", name: "POLAND", label: "Poland" },
      { value: "172", name: "PORTUGAL", label: "Portugal" },
      { value: "173", name: "PUERTO RICO", label: "Puerto Rico" },
      { value: "174", name: "QATAR", label: "Qatar" },
      { value: "175", name: "REUNION", label: "Reunion" },
      { value: "176", name: "ROMANIA", label: "Romania" },
      { value: "177", name: "RUSSIAN FEDERATION", label: "Russian Federation" },
      { value: "178", name: "RWANDA", label: "Rwanda" },
      { value: "179", name: "SAINT HELENA", label: "Saint Helena" },
      {
        value: "180",
        name: "SAINT KITTS AND NEVIS",
        label: "Saint Kitts and Nevis",
      },
      { value: "181", name: "SAINT LUCIA", label: "Saint Lucia" },
      {
        value: "182",
        name: "SAINT PIERRE AND MIQUELON",
        label: "Saint Pierre and Miquelon",
      },
      {
        value: "183",
        name: "SAINT VINCENT AND THE GRENADINES",
        label: "Saint Vincent and the Grenadines",
      },
      { value: "184", name: "SAMOA", label: "Samoa" },
      { value: "185", name: "SAN MARINO", label: "San Marino" },
      {
        value: "186",
        name: "SAO TOME AND PRINCIPE",
        label: "Sao Tome and Principe",
      },
      { value: "187", name: "SAUDI ARABIA", label: "Saudi Arabia" },
      { value: "188", name: "SENEGAL", label: "Senegal" },
      {
        value: "189",
        name: "SERBIA AND MONTENEGRO",
        label: "Serbia and Montenegro",
      },
      { value: "190", name: "SEYCHELLES", label: "Seychelles" },
      { value: "191", name: "SIERRA LEONE", label: "Sierra Leone" },
      { value: "192", name: "SINGAPORE", label: "Singapore" },
      { value: "193", name: "SLOVAKIA", label: "Slovakia" },
      { value: "194", name: "SLOVENIA", label: "Slovenia" },
      { value: "195", name: "SOLOMON ISLANDS", label: "Solomon Islands" },
      { value: "196", name: "SOMALIA", label: "Somalia" },
      { value: "197", name: "SOUTH AFRICA", label: "South Africa" },
      {
        value: "198",
        name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
        label: "South Georgia and the South Sandwich Islands",
      },
      { value: "199", name: "SPAIN", label: "Spain" },
      { value: "200", name: "SRI LANKA", label: "Sri Lanka" },
      { value: "201", name: "SUDAN", label: "Sudan" },
      { value: "202", name: "SURINAME", label: "Suriname" },
      {
        value: "203",
        name: "SVALBARD AND JAN MAYEN",
        label: "Svalbard and Jan Mayen",
      },
      { value: "204", name: "SWAZILAND", label: "Swaziland" },
      { value: "205", name: "SWEDEN", label: "Sweden" },
      { value: "206", name: "SWITZERLAND", label: "Switzerland" },
      {
        value: "207",
        name: "SYRIAN ARAB REPUBLIC",
        label: "Syrian Arab Republic",
      },
      {
        value: "208",
        name: "TAIWAN, PROVINCE OF CHINA",
        label: "Taiwan, Province of China",
      },
      { value: "209", name: "TAJIKISTAN", label: "Tajikistan" },
      {
        value: "210",
        name: "TANZANIA, UNITED REPUBLIC OF",
        label: "Tanzania, United Republic of",
      },
      { value: "211", name: "THAILAND", label: "Thailand" },
      { value: "212", name: "TIMOR-LESTE", label: "Timor-Leste" },
      { value: "213", name: "TOGO", label: "Togo" },
      { value: "214", name: "TOKELAU", label: "Tokelau" },
      { value: "215", name: "TONGA", label: "Tonga" },
      {
        value: "216",
        name: "TRINIDAD AND TOBAGO",
        label: "Trinidad and Tobago",
      },
      { value: "217", name: "TUNISIA", label: "Tunisia" },
      { value: "218", name: "TURKEY", label: "Turkey" },
      { value: "219", name: "TURKMENISTAN", label: "Turkmenistan" },
      {
        value: "220",
        name: "TURKS AND CAICOS ISLANDS",
        label: "Turks and Caicos Islands",
      },
      { value: "221", name: "TUVALU", label: "Tuvalu" },
      { value: "222", name: "UGANDA", label: "Uganda" },
      { value: "223", name: "UKRAINE", label: "Ukraine" },
      {
        value: "224",
        name: "UNITED ARAB EMIRATES",
        label: "United Arab Emirates",
      },
      { value: "225", name: "UNITED KINGDOM", label: "United Kingdom" },
      { value: "226", name: "UNITED STATES", label: "United States" },
      {
        value: "227",
        name: "UNITED STATES MINOR OUTLYING ISLANDS",
        label: "United States Minor Outlying Islands",
      },
      { value: "228", name: "URUGUAY", label: "Uruguay" },
      { value: "229", name: "UZBEKISTAN", label: "Uzbekistan" },
      { value: "230", name: "VANUATU", label: "Vanuatu" },
      { value: "231", name: "VENEZUELA", label: "Venezuela" },
      { value: "232", name: "VIET NAM", label: "Viet Nam" },
      {
        value: "233",
        name: "VIRGIN ISLANDS, BRITISH",
        label: "Virgin Islands, British",
      },
      {
        value: "234",
        name: "VIRGIN ISLANDS, U.S.",
        label: "Virgin Islands, U.s.",
      },
      { value: "235", name: "WALLIS AND FUTUNA", label: "Wallis and Futuna" },
      { value: "236", name: "WESTERN SAHARA", label: "Western Sahara" },
      { value: "237", name: "YEMEN", label: "Yemen" },
      { value: "238", name: "ZAMBIA", label: "Zambia" },
      { value: "239", name: "ZIMBABWE", label: "Zimbabwe" },
    ],
  },
];

exports.id_types = [
  {
    label: "",
    options: [
      { value: "id", label: "CIN" },
      { value: "driving_license", label: "Driver Licencse" },
      { value: "passport", label: "Passport " },
    ],
  },
];
exports.block_periods = [
  {
    label: "",
    options: [
      { value: "4", label: "8 hours", },
      { value: "0", label: "15 days" },
      { value: "1", label: "1 month" },
      { value: "2", label: "3 months " },
      { value: "-1", label: "Forever" },
      { value: "3", label: "Unblock " },
    ],
  },
];
exports.transactions_types = [
  {
    label: "",
    options: [
      {
        value: 1,
        label: "Cotisation en attente de transmission à [DRIVE_NAME]",
      },
      {
        value: 2,
        label:
          "Cotisation à recevoir de [PASSENGER_NAME] après déduction des frais de service",
      },
      {
        value: 6,
        label: "Compte à créditer dans le réseau Tasshilat",
      },
      {
        value: 7,
        label: "Compte crédité dans le réseau Tasshilat",
      },
      {
        value: 8,
        label: "Compte à créditer dans le réseau Cashplus",
      },
      {
        value: 9,
        label: "Compte crédité dans le réseau Cashplus",
      },
      {
        value: 5,
        label: "Wallet créditée par carte bancaire",
      },
      {
        value: 27,
        label: "Solde offert par Pip Pip Yalah",
      },
      {
        value: 12,
        label: "Annulation gratuite d'une réservation après validation",
      },
      {
        value: 17,
        label:
          "Pénalité d'annulation d'un covoiturage avec une ou plusieurs réservations confirmées",
      },
      {
        value: 18,
        label: "Remboursement suite à l'annulation du conducteur",
      },
      {
        value: 11,
        label: "Cotisation débloquée",
      },
      {
        value: 13,
        label: "Pénalité d'annulation d'une réservation après validation",
      },
      {
        value: 14,
        label: "Dédommagement pour annulation tardive d'une réservation",
      },
      {
        value: 21,
        label: "Solde transmis sur ton compte bancaire [RIB_NAME]",
      },
      {
        value: 3,
        label: "Cotisation transmise à [DRIVER_NAME] ",
      },
      {
        value: 4,
        label:
          "Cotisation reçue de [PASSENGER_NAME] après déduction des frais de service (10%).",
      },
      {
        value: 19,
        label: "Pénalité d'absence du conducteur à l'heure du départ",
      },
      {
        value: 20,
        label: "Remboursement suite à l'absence du conducteur",
      },
      {
        value: 15,
        label: "Pénalité d'absence passager(s) à l'heure du départ",
      },
      {
        value: 16,
        label:
          "Dédommagement pour absence d'un passager (ou plusieurs) à l'heure du départ",
      },
      {
        value: 22,
        label: "Virement impossible à exécuter",
      },
      {
        value:30,
        label:"Dédommagement du passager lorsqu'il paie en cash"
      },
      {
        value:31,
        label:"Cotisation retirée du conducteur lors du paiement en cash du passager"
      }
    ],
  },
];


exports.wallet_types = [
  {
    label: "",
    options: [
      { value: "gift_wallet", label: "Gift" },
      { value: "real_wallet", label: "Wallet Amount" },
    ],
  },
]


exports.ride_statuses = [
  {
    label: "",
    options: [
      { value: "1", label: "Ongoing" },
      { value: "2", label: "Cancel For Free" },
      { value: "3", label: "Completed" },
      { value: "4", label: "Cancel Paid (with paid reservations)" },
      { value: "5", label: "Driver absent" },
    ],
  },
];
exports.reservation_statuses = [
  {
    label: "",
    options: [
      { value: "0", label: "Reset" },
      { value: "1", label: "Scan" },
      { value: "2", label: "Passenger Cancel FREE" },
      { value: "3", label: "Passenger Cancel PAID" },
      { value: "4", label: "Passenger Absence" },
    
    ],
  },
];

exports.memberDashboardSelection = [
  {
    label: "",
    options: [
      { value: "0", label: "Drivers" },
      { value: "1", label: "Passengers" },
      { value: "2", label: "All" },
    ],
  },
];